import React from 'react'
import styled from 'styled-components'

const Bg = styled.div`
  background: #c0e9df;
  min-height: 100vh;
  width: 100%;
  font-family: 'Nunito', 'sans-serif';
  color: #111112;
`

const ScrollableWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  height: 100vh;
  width: 100%;
  overflow-y: visible;
  @media (max-height: 700px) {
    height: 100%;
  }
`

const PrivacyLink = styled.a`
  font-family: 'Nunito', 'sans-serif';
  font-size: 1rem;
  font-weight: 300;
  letter-spacing: 1px;
  width: 100%;
  color: rgb(66, 82, 110);
  text-align: center;
  color: #6b449c;
`

const Background = ({ children }) => (
  <Bg>
    <ScrollableWrapper>
      {children}
      <span style={{ marginTop: '1.5rem' }}>
        <PrivacyLink href='https://welbo.eu/privacy'>Privacy Policy</PrivacyLink>
      </span>
    </ScrollableWrapper>
  </Bg>)

export default Background
